.sidebar{
    width: 82px;
    height: calc(100vh - 22px);
    position: fixed;
    background: #020203;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding-top: 38px;
    z-index: 9;
    flex-wrap: wrap;
}
/* .sidebar:hover .submenu_sidebar{
    left: 82px;
} */
.submenu_sidebar.active{
    left: 82px;
}
/* .sidebar:hover{
    margin-right: -82px;
} */
.line_px_menu{
    opacity: 0;
}
.sidebar.active .line_px_menu{
    opacity: 1;
}

.hover_logo{
    display: none;
}
.sidebar.active .hover_logo{
    display: block;
}
.sidebar.active .def_logo{
    display: none;
}
.sid_menu{
    max-height: 180px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.logo{
    position: relative;
    z-index: 999;
    /*left: 50px;*/
}
.sidebar.active .logo.all{
    left: 87px;
    top: 0px;
    max-height: 50px;
}

.menu{
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 90px;
    align-items: flex-end;
    position: absolute;
    height: 90px;
    top: 80px;
    margin-top: 20px;
}
.menu a{
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 999;
    min-height: 45px;
}
.menu a img{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}
.menu a svg{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}
.hover-image {
    display: none; 
}
.logout_but{
    position: relative;
}
.logout_but img{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}
.menu a:hover .hover-image, .logout_but:hover .hover-image {
display: inline; 
}

.sidebar.active .menu a.activemenu:hover .hover-image{
    display: none !important; 
}

.menu a:hover .default-image, .logout_but:hover .default-image {
display: none; 
}
.sidebar.active .menu a.activemenu:hover .default-image{
    display: block !important; 
}

.logout{
    max-height: 75px;
    position: absolute;
    bottom: 50px;
    cursor: pointer;
}
.lang_sidebar{
    margin-bottom: 31px;
}
@media (max-width: 900px){
    .sidebar{
        display: none;
    }
}
.sidebar_col{
    height: 100%;
    width: 100%;
    background: rgba(2, 2, 3, 1);
    display: flex;
    justify-content: center;
}
.submenu_sidebar{
    width: 180px;
    height: 100vh;
    position: absolute;
    top: 0;
    left: -180px;
    background: #020203;
    padding-top: 114px;
}
.submenu_sidebar.active{
    left: 82px;
}
.submenu_lines{
    display: flex;
    flex-wrap: wrap;
}
.title_sub_sidermenu{
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(245, 197, 56, 1);
    max-width: 153px;
    margin-bottom: 22px;
}
.submenu_line{
    width: 130px;
    height: 14px;
    padding: 12px 20px 12px 10px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #fff;
    margin-bottom: 12px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}
.submenu_line:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(90deg, rgba(245, 197, 56, 0) 0%, #F5C538 100%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}


.submenu_line.active{
    background: rgba(45, 50, 57, 1);
}
.line_px_menu{
    height: 190px;
    width: 1px;

    position: absolute;
    left: 68px;
    top: 15px;
}

.anduin::before{
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(250deg, #F5C538 0.22%, rgba(245, 197, 56, 0) 100%); 
}

.anduin2::before{
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(253deg, rgba(245, 197, 56, 0) 0.46%, #F5C538 29.84%, rgba(245, 197, 56, 0) 99.86%); 
}

.anduin3::before{
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(285deg, rgba(245, 197, 56, 0) 0.46%, #F5C538 51.28%, rgba(245, 197, 56, 0) 99.86%); 
}
.menu a{
    position: relative;
    display: flex;
    align-items: center;
}
.hover_meny_v{
    width: 79px;
    border-radius: 4px;
    background: rgba(245, 197, 56, 1);
    position: absolute;
    z-index: 99;
    color: rgba(244, 245, 247, 1);
    font-size: 8px;
    font-weight: 400;
    line-height: 9.6px;
    letter-spacing: -0.01em;
    text-align: left;
    left: 60px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
}
.hover_lang_v{
    width: 40px;
    border-radius: 4px;
    background: rgba(245, 197, 56, 1);
    position: absolute;
    z-index: 99;
    color: rgba(244, 245, 247, 1);
    font-size: 8px;
    font-weight: 400;
    line-height: 9.6px;
    letter-spacing: -0.01em;
    text-align: left;
    left: 27px;
    top: -6px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s;
}
.hover_meny{
    width: 62px;
    padding: 8px;
    border-radius: 4px;
    background: rgba(28, 30, 32, 1);
}
.menu a:hover .hover_meny_v{
    opacity: 1;
    z-index: 999;
    transition: all 0.5s;
}

.lang_sidebar:hover .hover_lang_v{
    opacity: 1;
    z-index: 999;
    transition: all 0.5s;
}
.hover_lang_v .hover_meny{
    width: 23px;
}
    
.hover_lang_v span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 14px;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    color: #fff;


}
.hover_lang_v span:first-child{
    margin-bottom: 8px;
}
.hover_lang_v .active{
    background: rgba(45, 50, 57, 1);
}