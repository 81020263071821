.segmentation {
    width: calc(100% + 280px);
    min-height: 100vh;
    background: #fff;
    display: flex;
    position: relative;
}
.segmentation.hold{
    transform: translateX(80px);
}
.body_segment{
    width: calc(100% - 280px);
    min-height: 100vh;
}
.menu_segment img{
    position: relative;
    left: 118px;
}
.submenu{
    height: calc(100vh - 126px);
}
.block_sub_menu{
    width: 295px;
    margin-left: 24px;
    padding-top: 24px;
}
.title_block_sub_menu{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: #F4F5F7;
    text-transform: uppercase;
    padding-bottom: 22px;
}
.sub_menu_button{
    width: calc(100% - 74px);
    height: calc(64px  - 40px);
    padding: 20px 30px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
    cursor: pointer;
    position: relative;
}
.sub_menu_button.active{
    background: #2D3239;
}
.sub_menu_button svg{
    margin-right: 8px;
}
.sub_menu_button:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(293.25deg, #F5C538 2.19%, rgba(245, 197, 56, 0)39.26%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.hold_button{
    margin-top: 22px;
    cursor: pointer;
}
.hov_arrow{
    display: none;
}
.hold_button:hover .def_arrow{
    display: none;
}
.hold_button:hover .hov_arrow{
    display: block;
}
.header-segment.dataset{
    margin-top: 60px;
}
@media (max-width: 900px)
{
    .segmentation {
        opacity: 0;
    }
}
.segmentation .popupInfo{
    position: absolute;
}
.segmentation .popupInfofon{
    height: 100vh;
}