.contop_block{
    width: calc(100%);
    display: flex;


}
.contop_block_left{
    width: calc(42% - 60px);
    background: rgba(234, 236, 240, 1);
    border-radius: 8px;
    margin-right: 20px;
    padding: 20px;
}
.contop_block_right{
    width: calc(58% - 40px);
    background: rgba(234, 236, 240, 1);
    border-radius: 8px;
    padding: 20px;
}
.contop_block_left_body{
    width: 100%;
    border-radius: 8px;
    display: flex;
}
.contop_block_right_body{
    width: calc(100% );
    height: 300px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.contop_block_title{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 12px;
    min-width: 100%;
    padding-bottom: 30px;
}
.contop_block_left_body_1{
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    width: 100px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.04em;
    text-align: left;
    color: rgba(66, 133, 244, 1);

}
.contop_block_left_body_1 span{
    transform-origin: bottom right;
    transform: rotate(-90deg);
    display: block;
    position: relative;
    left: -25px;
    top: -34px;
}
.contop_block_left_body_2{
    min-width: calc(100% - 72px);
    display: flex;
    flex-wrap: wrap;

}
.contop_block_left_body_2_line{
    min-width: 100%;
    min-height: 56px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.01em;
    text-align: left;
    position: relative;
    color: rgba(66, 133, 244, 1);
}
.contop_block_left_body_2_line_bl{
    display: flex;
    flex-wrap: wrap;
    min-width: 100%;
}
.contop_block_left_body_2_line_1, .contop_block_left_body_2_line_2{
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}
.contop_block_left_body_2_line_1{
    margin-bottom: 12px;
}
.contop_block_left_body_2_line_2{
    min-height: 85px;
}
.contop_block_left_col_1{
    min-width: 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    white-space: nowrap;
}
.contop_block_left_col_2{
    min-width: calc(50% - 12px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
}

.contop_block_left_col_3{
    min-width: calc(50% - 12px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
}
.zeretmortis .contop_block_left_col_2, .zeretmortis .contop_block_left_col_3{
    min-width: 120px;
}
.zeretabmortis_line .contop_block_left_col_2, .zeretabmortis_line .contop_block_left_col_3{
    min-width: 156px;
}


.vbmulty1{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
}

.vb1{
    background: rgba(169, 199, 251, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border-top-left-radius:8px ;
    border:1px solid #fff;
}
.vb2{
    background: rgba(218, 232, 253, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border-top-right-radius:8px ;
    border:1px solid #fff;
    border-left:0px;
}
.vb3{
    background: rgba(218, 232, 253, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border-bottom-left-radius:8px ;
    border:1px solid #fff;
    border-top:0px;
}
.vbmulty02{
    background: rgba(218, 232, 253, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
    border-top:0px;
}
.vbmulty2{
    background: rgba(218, 232, 253, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
    border-top:0px;
    border-left:0px ;
}
.vbmulty13{

    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
    border-top:0px;
    border-bottom-left-radius: 8px;
}
.vbmulty23{

    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
    border-top:0px;
    border-left: 0px;
}
.vbmulty33{

    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border:1px solid #fff;
    border-top:0px;
    border-bottom-right-radius: 8px;
    border-left: 0px;
}
.vb4{
    background: rgba(169, 199, 251, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border-bottom-right-radius:8px ;
    border:1px solid #fff;
    border-left:0px;
    border-top:0px;
}

.vbmurly3{
    background: rgba(169, 199, 251, 1);
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    min-height: 85px;
    border-top-right-radius:8px ;
    border:1px solid #fff;
}

.contop_block_left_body_2_line_svitch{
    width: 400px;
    height: 10px;
    background: #fff;
    border-radius: 5px;
    position: absolute;
    right: 25px;
}
.contop_block_left_body_2_line_svitch_circyle{
    width: 17px;
    height: 17px;
    border-radius: 20px;
    border: 2px solid rgba(244, 245, 247, 1);
    background: rgba(245, 197, 56, 1);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -5px;
}
.contop_block_right_body_line{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;

    color: rgba(2, 2, 3, 1);
    min-height: 20px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}
.contop_block_right_body_line div{
    height: 20px;
    background: rgba(66, 133, 244, 1);
    border-radius: 0px 4px 4px 0px;
    margin-right: 20px;
}
.contop_block_right_body_line span{

    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 1);
}
.contop_block_right_body_line_2{
    /* min-width: 400px;
    max-width: 400px; */
}
.contop_block_right_body_line_1{
    /* margin-right: 35px; */
}
.zeretabmortis_line{
    flex-wrap: wrap;
}
.zeretabmortis{
    min-width: calc(100% - 40px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
.alik{
    /* overflow: scroll; */
}
.alik::-webkit-scrollbar{
    height: 5px;
    width: 5px;

  }
  .alik::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(255, 255, 255, 1);
    margin-top: 10px;
}
 
.alik::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}
.lintop_bl{
    width: 100%;
    padding-left: 20px;
}
.contop_block_left_col_1{
    transform: rotate(-90deg);
    transform-origin: top left;
    width: 40px;
    position: relative;
    left: -19px;
    top: 66px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 1);
    text-align: center;
    position: absolute;
    text-align: center;
    justify-content: center;
}
.contop_block_left_col_2, .contop_block_left_col_3{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 1);
}