.gsetings-page {
    width: calc(100% - 82px);
    min-height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    padding-left: 82px;
}
@media (max-width: 900px)
{
    .gsetings-page {
        width: 100%;
    }
}