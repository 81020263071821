.fourblock{
    width: calc(100%);

    display: flex;

    margin-bottom: 40px;
    border-radius: 8px;
}
.block_four{
    width: calc(25% - 20px);
    padding: 10px 10px;
    background: rgba(244, 245, 247, 1);
    border-radius: 8px;
}
.block_four_body
{
    width: calc(100% - 32px);
    background: rgba(234, 236, 240, 1);
    height: 100%;
    padding: 16px;
    border-radius: 8px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.block_four_1{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(66, 133, 244, 1);
    text-transform: uppercase;
    margin-bottom: 16px;
    min-width: 100%;
}
.block_four_2{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(2, 2, 3, 0.6);
    margin-bottom: 10px;
}
.block_four_3{


    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: center;

    color: rgba(2, 2, 3, 1);
    margin-bottom: 10px;
}
.block_four_4{
    width: calc(100% - 20px);
    height: 1px;
    margin-left: 10px;
    background: rgba(245, 197, 56, 1);
    margin-bottom: 10px;
}
.block_four_5{
    display: flex;

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;

    color: rgba(2, 2, 3, 0.4);
}
.block_four_5 div:first-child{
    padding-right: 10px;
}