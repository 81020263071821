.loading_icon.text{
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(234, 67, 52, 1);

}
.loading_text.small{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;

    color: rgba(255, 255, 255, 1);
}
.loading_icon.text{
    min-height: 68px;
    margin-top: 60px;
}