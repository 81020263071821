.welcome{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 999;
}
.welcome .logo{
    width: 100%;
    text-align: center;
}
.welcome .logo img{
    max-width: 210px;
}
.welcome .logo svg{
    margin-bottom: 40px;
}
.welcome .utp{
    width: 100%;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #fff;
    margin-top: 22px;
    margin-bottom: 0px;
    
}

.welcome .utp span{
    color: #F5C538;
}
.welcome .start-buttons{
    display: flex;
    max-width: 1026px;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
}
.welcome .start-button{
    min-width: 500px;
    max-width: 500px;
    height: 100px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(31, 35, 39, 1);
    margin-bottom: 40px;
    justify-content: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-decoration: none;
    color: #fff;
    position: relative;
}
.welcome .start-button:hover{
    background: rgba(45, 50, 57, 1);
    color: rgba(245, 197, 56, 1);
}
.welcome .start-button:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(266.36deg, #F5C538 2.99%, rgba(28, 30, 32, 0) 97.01%);

       -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
@media (max-height: 900px)
{
    .welcome .utp{
        font-size: 20px;
        line-height: 64px;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .welcome .logo img {
        max-width: 190px;
    }
    .welcome .start-buttons {
        max-width: 966px;
    }
    .welcome .start-button 
    {
        min-width: 500px;
        max-width: 500px;
        font-size: 24px;
        height: 100px;
    }
}
@media (max-width: 1024px)
{
    .welcome .utp{
        font-size: 60px;
        line-height: 65px;
        margin-bottom: 60px;
    }
    .welcome .logo img {
        max-width: 190px;
    }
    .welcome .start-buttons {
        max-width: 826px;
    }
    .welcome .start-button 
    {
        min-width: 400px;
        max-width: 400px;
        font-size: 20px;
    }
}
@media (max-width: 900px)
{
   
    .welcome .start-buttons {
        max-width: 100%;
    }
    .welcome .start-button 
    {
        min-width: 100%;
        max-width: 100%;
        font-size: 18px;
        height: 80px;
    }
}
@media (max-width: 768px)
{
   
    .welcome .utp{
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 40px;
    }
}
.betta{
    width: 49px;
    height: 20px;
    top: 40px;
    right: 40px;
    border-radius: 8px;
    border: 1px solid rgba(245, 197, 56, 1);
    background: rgba(28, 30, 32, 1);
    position: absolute;
}
.betta_inside{
    width: 47px;
    height: 22px;
    border-radius: 6px;
    background: rgba(28, 30, 32, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(244, 245, 247, 1);
    position: absolute;
    top: -1px;
    left: 1px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.welcome .utp_beta{
    font-size: 36px;
    font-weight: 500;
    line-height: 43.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.welcome .utp_beta span{

    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(142, 142, 147, 1);
}