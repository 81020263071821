.tabstarget{
    width: calc(100%);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    background: rgba(244, 245, 247, 1);
    border-radius: 8px;
    padding-bottom: 30px;
}
.tabstarget_head{
    min-width: 100%;
    display: flex;
    margin-top: 30px;
}
.tabstarget_head_tab{
    height: 18px;
    padding: 10px;
    border-radius: 8px ;
    color: rgba(2, 2, 3, 1);

    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin-right: 20px;
    cursor: pointer;
}
.tabstarget_head_tab.active{
    background: rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
}
.tabstarget_body{
    background: rgba(234, 236, 240, 1);
    padding: 16px 12px 16px 12px;
    border-radius:8px;
    border-top-left-radius:0px ;
    
}
.tabstarget_body_tab{
    display: none;
}
.tabstarget_body_tab.active{
    display: flex;
    flex-wrap: wrap;
}
.tabstarget_body_tab_line{
    min-width: 100%;
    height: 74px;
    border-bottom: 1px solid rgba(2, 2, 3, 0.6);
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}
.tabstarget_body_tab_line:last-child{
    border: 0px;
}
.tabstarget_body_tab_line_col1{
    min-width: 125px;
    margin-right: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;

}
.tabstarget_body_tab_line_col2{
    min-width: 300px;
    margin-right: 20px;
}
.zagruz_line{
    width: 100%;
    background: #fff;
    height: 20px;
    border-radius: 0px 4px 4px 0px;
}
.zagruz_line span{
    display: block;
    background: rgba(51, 167, 83, 1);
    height: 20px;
    border-radius: 0px 4px 4px 0px;
}
.tabstarget_body_tab_line_col3{
    min-width: 100px;
    margin-right: 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.4);
}
.tabstarget_body_tab_line_col3 span{
    color: rgba(2, 2, 3, 1);
}
.tabstarget_body_tab_line_col4{
    min-width: 100px;
    margin-right: 20px;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: left;

}
.tabstarget_body_tab_line_col5{
  
    max-width: 312px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
}
.tabstarget_body_tab_line_col5 span{
    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(235, 115, 27, 1);
}