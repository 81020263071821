.campaigns {
    width: calc(100% - 82px);
    min-height: 100vh;
    background: #fff;
    display: flex;
    align-items: center;
    padding-left: 82px;

    width: calc(100% + 394px);
    min-height: 100vh;
    background: #fff;
    display: flex;
    align-items: flex-start;
}
.campaign.hold{
    transform: translateX(180px);
    width: calc(100% + 180px);
}
.campaign .body_segment{

    padding-top: 98px;
}

@media (max-width: 900px)
{
    .campaigns {
        width: 100%;
    }
}
.campaign .filter__menu-list{
    width: 100%;
}