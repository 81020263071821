.error-message {
  font-size: 18px;
  font-weight: 500;
  line-height: 18.9px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #EA4334;
  position: relative;
  top: -14px;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 999;
}

.login-container form {
  width: calc(394px - 80px);
  padding: 40px;
  border-radius: 20px;
  background-color: #fff;
}
.login-container h1{
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: #2C2C2C;
  margin-bottom: 40px;
}
.login-container h1 span{
  color: rgba(66, 133, 244, 1);
}
.login-container label {
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: left;
}

.login-container .auth-input {
  font-family: "Manrope", sans-serif;
  width: calc(100% - 60px);
  height: 40px;
  border-radius: 8px;
  padding: 0px 30px;
  background: #F4F5F7;
  background-color: #F4F5F7;
  margin-bottom: 10px;
  border: 0px;
  font-size: 15px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.01em;
  margin-bottom: 24px;
  outline: 0 !important;
}
.password-toggle-icon {
  position: relative;
  top: -56px;
  left: 258px;
  cursor: pointer;
  height: 0px;
  display: block;
}
.error ~ .password-toggle-icon {
  top: -57px;
}

.login-container button {
  width: 100%;
  cursor: pointer;
  height: 59px;
  margin-top: 16px;
  background: #2D3239;
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 49px;
  letter-spacing: -0.03em;
  color: #FFFFFF;
}

.login-container button:hover {
  color: rgba(255, 255, 255, 0.15);
}

.password-toggle-icon:hover svg path{
  stroke: #F5C538
}
.password-toggle-icon:hover svg line{
  stroke: #F5C538
}
.auth-input.error{
  border: 1px solid #EA4334
}