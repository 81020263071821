.login-page {
    width: calc(100% - 82px);
    height: 100vh;
    background-position: right 0px;
    background-size: 860px 860px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 82px;
    background-color: #020203;
    justify-content: center;
    position: relative;
    max-height: 100vh;
    overflow: hidden;
}
@media (max-width: 900px)
{
    .login-page {
        height: initial;
        min-height: 100vh;
        width: 100%;
    }
}