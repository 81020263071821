.gsettings{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    margin-left: -180px;
    margin-top: -100px;

}
.block-gsettings{
    width: calc601px;
    margin-top: 96px;
}
.form-gsettings, .form-products{
    width: calc(100% - 40px);
    padding: 20px;
    position: relative;
    border: 1px solid #fff;
    
}
.form-products{
    margin-top: 40px;
}


.form-gsettings:hover::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(107.75deg, #F5C538 2.77%, rgba(245, 197, 56, 0)); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }


.form-gsettings h1, .form-products h1{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: uppercase;
    color: #020203;
    margin-bottom: 24px;
    margin-top: 0px;
}
.line-input{
    width: calc(514px - 40px);
    padding: 10px 20px;
    height: calc(60px - 20px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 999;
}
.pole_line_prod{
    width: calc(434px - 40px);
    padding: 10px 0px;
}
.line-input-mapproducts{
    width: calc(514px - 0px);
    padding: 10px 0px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.line-input label{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    color: #020203;
}
.gset-input{
    min-width: calc(314px - 60px);
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    background: #F4F5F7;
    border-radius: 8px;
    border: none;
    color: #020203;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    outline: 0 !important;
}
.nedopen{
    opacity: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.form-gsettings:hover .nedopen{
    opacity: 1;
}
.popupInfofon{
    
    background: rgba(0,0,0,0);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.popupInfo{
    padding: 40px 46px;
    background: rgba(2, 2, 3, 1);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: calc(680px - 80px);
    max-height: calc(476px - 92px);
    border-radius: 20px;
}
.popupInfo.deleteProduct{
    background: rgba(28, 30, 32, 1);
}
.popupInfo .top-element{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.namepopup{
    color: #F4F5F7;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;

}
.separator{
    width: 36px;
    height: 1px;
    background: rgba(255,255,255,0.15);
}
.buttonspopup{
    min-width: 172px;
    display: flex;
    justify-content: space-between;
}
.buttonsave, .buttonexit{
    width: 80px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.2);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    color: rgba(255,255,255,0.2);;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.buttonexit{
    border: 1px solid #fff;
    opacity: 1;
    cursor: pointer;
    position: relative;
}
.buttonsave.active{
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    position: relative;
}
.form-element{
    margin-top: 60px;
}
.popup-inputs{
    display: flex;
    width: 438px;
    justify-content: space-between;
}
.pop-input{
    width: calc(203px - 20px);
    font-family: Manrope;
    height: 38px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    border: none;
    background: #2D3239;
    outline: 0 !important;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(255,255,255,0.6);
}
.pop-area{
    width: calc(600px - 60px);
    height: calc(200px - 40px);
    padding: 20px 30px;
    border-radius: 4px;
    background: #2D3239;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: white;
    margin-top: 40px;
    outline: 0 !important;
    border: none;
    font-family: "Manrope", sans-serif;
    resize: none
}
.pop-area::-webkit-scrollbar
{
    height: 10px;
    width: 7px;
}
.pop-area::-webkit-scrollbar-track 
{
    border-radius: 10px;
    background: #020203;
}
 
.pop-area::-webkit-scrollbar-thumb 
{
    border-radius: 10px;
    background: rgba(245, 197, 56, 1);
}


textarea:-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder { /* Modern browsers */
    color: white;
    font-family: "Manrope", sans-serif;
}
.line-input-buttonadd{
    display: flex;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    align-items: center;
    text-transform: uppercase;
    cursor: pointer;
    color: rgba(2, 2, 3, 0.6);
}

.line-input-buttonadd:hover{
  color: rgba(2, 2, 3, 1);
}
.line-input-buttonadd svg{
    margin-right: 12px;
}
.line-input-buttonadd:hover .default-image{
    display: none
}
.line-input-buttonadd:hover .hover-image{
    display: block
}
.line_producta{
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #fff;
    position: relative;
}

.line_producta:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(107.75deg, #F5C538 2.77%, rgba(245, 197, 56, 0)); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}

.buttonsave.active:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(293.25deg, #F5C538 2.19%, rgba(245, 197, 56, 0)39.26%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.buttonsave.active:hover{
    border: 0px;
}
.line_producta:hover .but_line_prod svg{
    opacity: 1;
}
.buttonexit:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(293.25deg, #EA4334 2.19%, rgba(234, 67, 52, 0) 39.26%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.buttonexit:hover{
    border: 1px solid rgba(255,255,255,0);
}
.buttonexit:hover svg path {
    stroke: rgba(234, 67, 52, 1);
}

.pole_line_prod{
    min-width: 55px;
    display: flex;
    justify-content: space-between;
}
.but_line_prod{
    width: 55px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 999;
}
.but_line_prod svg{
    cursor: pointer;
    opacity: 0;
}

.css-1dimb5e-singleValue{
    color: #fff!important;
}
.css-1u9des2-indicatorSeparator{
    display: none;
}
.selectproduct{
    outline: none;
}
.selectplace{
    color: rgba(255, 255, 255, 1)!important;
}
.css-1dimb5e-singleValue{
    /*color: rgba(245, 197, 56, 1)!important;*/
}
.filter__option{
    padding-left: 0px !important;
}
.deleteProduct{
    width: calc(659px - 80px);
    height: calc(370px - 92px);
}
.deleteProduct .separator{
   width: 450px;
}
.deleteProduct .buttonspopup{
    min-width: 80px;
}
.titlePopupDelete{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #fff;

}
.titlePopupDelete span{
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(234, 67, 52, 1);
    text-transform: lowercase;
}
.buttonsPopupDelete{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    height: 59px;
    min-width: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: space-around;
    margin-top: 50px;
    
}
.buttonPopupDelete{
    cursor: pointer;
    width: 177px;
    height: 38px; 
    text-align: center;
    margin-top: 0px;
    position: relative;
    padding-top: 20px;
}
.buttonPopupDelete:hover{
    color: rgba(245, 197, 56, 1); 
}
.buttonPopupDelete:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.filter__menu-list {
    overflow-x: hidden;
    border-top: 1px solid rgba(245, 197, 56, 1);
    width: calc(100% - 24px);
    margin: auto;
    margin-bottom: 12px;
}
.filter__option{
    padding-left: 0px;
}
.css-wxpjh6-control{
    
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
}

.css-yehhs4-control{
    border-radius: 8px !important;
    transition: all 0ms !important;
    
}
.css-1u5ekcs-control{
    border-radius: 0px !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    transition: all 0ms !important;
}
.selectproduct .filter__control--menu-is-open{
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.arrowprod:hover path{
    fill: rgba(245, 197, 56, 1);
}