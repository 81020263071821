.campaign{
    display: flex;
}
.tittles_compaings{
    display: flex;
    padding-left: 64px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
}
.tittles_compaings div{
    margin-right: 20px;
}
.tittles_compaings .title_compaing{
    min-width: 203px;
}
.tittles_compaings .title_compaing.tc2{
    min-width: 90px;
}
.line_compain{
    display: flex;
    align-items: center;
    margin-top: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
}
.line_compain:hover{
    z-index: 9999;
}
.line_compain:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}


.line_input_compaing{
    width:203px;
    min-width: 182px;
    max-width: 182px;
    height: 18px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(244, 245, 247, 1);
    background: rgba(255, 255, 255, 1);
    outline: none;
    margin-right: 20px;
    position: relative;
    z-index: 999;


    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;

}
.line_input_compaing.tc2{
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    margin-left: 20px;
    margin-right: 0px;
}
.open_compaing{
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
    z-index: 999;
}
.open_compaing svg:hover g{
    opacity: 0.6;
}
.open_compaing{
    opacity: 0;
    cursor: pointer;
}
.lines_compaings{
    margin-bottom: 50px;
}
.line_compain:hover .open_compaing{
    opacity: 1;
}
.line_compain .css-1fdsijx-ValueContainer{
    padding-left: 0px !important;
}
.line_compain .css-1hb7zxy-IndicatorsContainer
{
    margin-right: 0px;
}
.line_compain .selectplace{
    color: rgba(2, 2, 3, 0.6) !important;
}
.add_compaing{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    text-transform: uppercase;
    cursor: pointer;
}
.add_compaing svg{
    margin-right: 12px;
}
.campaign.hold{
    position: relative;
}
.campaign.hold .deleteProduct
{
    left: -100px;
}
.line_input_compaing_micro{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.line_input_compaing_micro svg{
    position: absolute;
    right: 30px;
    z-index: 999;
}
.popmicrosegment{
    max-width: calc(1111px - 80px);
    min-height: calc(715px - 92px);

}
.popmicrosegment .separator{
    width: 520px;
}
.line_head_micro{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tittle_head_micro{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    padding-right: 100px;
}
.value_head_micro{
    width: 196px;
    height:93px;
    border-radius: 8px ;
    background: rgba(45, 50, 57, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(244, 245, 247, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.value_head_micro span{
    width: 100%;
    display: inline;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    color: rgba(66, 133, 244, 1);
    max-height: 0px;
}   
.line_body_micro{
    display: flex;
    margin-top: 40px;
    align-items: flex-start;
    overflow-x: scroll;
    padding-bottom: 10px
}


.line_body_micro::-webkit-scrollbar{
    height: 6px;
    width: 6px;
  }
.line_body_micro::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(2, 2, 3, 1) !important;
    margin-top: 10px;
}
.line_body_micro::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}




.selectMickro{
    margin-right: 20px;
}

.selectMickro .filter__menu{
    top: 100%;
    position: absolute;
    width: 314px;
    z-index: 1;
    background-color: hsl(0, 0%, 100%);
    border-radius: 4px;
    box-shadow: none;
    margin-bottom: 8px;
    margin-top: 10px;
    box-sizing: border-box;
    background: rgba(45, 50, 57, 1);
    cursor: pointer;
}
.but_mickrosegment{
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    cursor: pointer;
    padding-top: 12px;
}
.but_mickrosegment svg{
    margin-right: 12px;
}
.mikro_select {
    width: 314px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 24px;
}
.mikro_select .element_select{
    min-width: 294px;
    min-height: 37px;
    padding: 11px 10px 10px 9px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: right;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: space-between;
    background: rgba(45, 50, 57, 1);
    align-items: center;
    position: relative;
    margin-bottom: 12px;
}
.mikro_select .element_select svg{
    position: relative;
    z-index: 999;
    cursor: pointer;
}
.element_select_left_title{
    position: relative;
    z-index: 999;
}
.mikro_select .element_select:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(91.77deg, #F5C538 1.26%, rgba(245, 197, 56, 0) 98.76%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.mikro_select .element_select:hover{
    color: #fff;
}
.mikro_select .element_select:hover .element_select_right{
    color: rgba(245, 197, 56, 1);
}
.element_select_left{
    display: flex;
    align-items: center;
}
.element_select_left svg{
    margin-right: 11px;
}
.element_select_right_subtitle{
    font-size: 12px;
}
.top_line_message{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 78%;
    border-bottom: 1px solid rgba(245, 197, 56, 1);
}
.back_message{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    left: 65px;
}
.back_message svg{
    margin-right: 12px;
}
.message_lines{
    display: flex;
    flex-wrap: wrap;
    padding-left: 50px;
    margin-top: 20px;
}
.message_line{
    width: 952px;
    padding: 0px 24px 24px 24px;
    border-radius: 10px;
    background: rgba(244, 245, 247, 1);
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.message_line .big_circle{
    width: 59px;
    height: 59px;
}
.message_line .small_circle{
    width: 24px;
    height: 24px;
    animation: Orbita2 15s linear infinite;
    left: 17px;
    top: 17px;
}


@keyframes Orbita2 {
    from {
      -webkit-transform: rotate(0deg) translateX(50px) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(50px) rotate(-360deg);
    }
}
.message_line  .loading_icon{
    margin-top: 50px;
    min-height: 100px;
}
.message_body_left{
    width: 544px;
    display: flex;
    flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
}
.message_body_right{
    width: 24px;
    display: flex;
    flex-wrap: wrap;
}
.message_body_line{
    min-width: 100%;
    display: flex;
    align-items: center;
}
.block_input{
    display: flex;
    flex-wrap: wrap;
}
.block_input label{
    min-width: 100%;    
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;   
    color: rgba(2, 2, 3, 1);
    margin-bottom: 8px;
}
.block_input input{
    width: 100%;
}
.but_add_message_line{
    width: 100%;
}
.but_add_message{
    display: flex;
    margin-top: 60px;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    text-transform: uppercase;
    cursor: pointer;
}
.but_add_message svg{
    margin-right: 12px;
}
.generate_message{
    width: 180px;
    height:19px;
    padding: 20px;
    border-radius: 8px;
    background: rgba(244, 245, 247, 1);
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(2, 2, 3, 0.6);
    margin-top: 60px;
    margin-left: 100px;
    cursor: pointer;
    margin-bottom: 40px;
}
.but_message_top{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    width: 22px;
    height: 16px;
    
    border-radius: 8px;
    position: relative;
    margin-left: 20px;
    cursor: pointer;
}
.message_body_center{
    display: flex;
}
.message_body_center .block_input{
    width: 90%;
}
.line_but_new_mess{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.but_message_top svg{
    margin-right: 8px;
}

.hover_but_message_top{
    display: none;
}


.but_message_top:hover .hover_but_message_top{
    display: block;
}
.but_message_top:hover .active_but_message_top{
    display: none;
}


.right_mes_but{
    min-height: 30px;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
}
.message_body_right svg{
    cursor: pointer;
}
.line_input_mess{
    height: 14px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(244, 245, 247, 1);
    outline: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    margin-bottom: 12px;
    margin-right: 20px;
}
.line_input_mess_name{
    min-width: 296px;
}
.line_input_mess_tool{
    min-width: 522px !important;
    max-width: 522px !important;
    margin-right: 0px;
}
.line_input_mess_signature, .line_input_mess_goal, .line_input_mess_tool{
    min-width: 203px;
}
.select_message_email .css-1fdsijx-ValueContainer{
    padding-left: 10px !important;
}
.select_message_email{
    margin-bottom: 12px;
    border-radius: 8px;
    max-height: 34px;
}
.message_lines .css-1xc3v61-indicatorContainer{
    padding: 5px;
}
.line_input_mess_purpose{
    width: 330px !important;
}
.line_input_mess_goal_input{
    min-width: 332px !important;
    max-width: 332px !important;
    margin-right: 0px !important;
}
.select_message_formula{
    margin-right: 20px;
}

.message_lines .selectplace {
    color: rgba(2, 2, 3, 0.6) !important;
}
.minimessage{
    display: flex;
    position: relative;
    padding: 10px;
    align-items: center;
    max-height: 56px;
    border-radius: 8px;
    background: rgba(244, 245, 247, 1);
}
.minimessage:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(91.33deg, #33A753 0.62%, rgba(51, 167, 83, 0) 102.11%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.hover_minimessage{
    display: none;
}
.minimessage:hover{
    background: #fff;
}
.minimessage:hover .hover_minimessage{
    display: block;
}

.minimessage.successful::before{
    display: none;
}
.minimessage.successful:hover::before{
    display: none;
}
.minimessage svg{
    margin-right: 8px;
    position: relative;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
}
.minimessage .hover_minimessage div{

    position: relative;
    z-index: 9999;
    cursor: pointer;
    opacity: 0;
    display: none;
}
.minimessage:hover svg{
    opacity: 1;
}
.minimessage:hover .hover_minimessage div{
    opacity: 1;
    display: flex;
}
.minimessage .cheka{
    margin-right: 20px;
}
.minimessage.successful{
    background: rgba(51, 167, 83, 0.13);
    border-radius: 10px;
    padding-left: 55px;
}
.minimessage input{
    margin-bottom: 0px;
}
.minimessage .select_message_email{
    margin-bottom: 0px;
}
.line_top_message{
    min-width: 100%;
    display: flex;
    justify-content: space-between;
}
.messages{
    display: flex;
    min-width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
}
.messages_line{
    flex-wrap: wrap;
    height: 720px;
}
.messages_line .line_top_message{
    max-height: 200px;
}
.messages_line .left_collumn{
    width: 780px;
    min-width: 780px;
}
.messages_line  .mesage_val{
    width: 740px;
    height: 330px;
    padding: 20px;
    outline: none;
    border: 0px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    font-family: "Manrope", sans-serif;
    color: rgba(2, 2, 3, 0.6);
    background: #fff;
    overflow-y: scroll;
}
.lineinput_line_message{
    max-width: 741px;
    min-width: 741px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-radius: 10px;
    align-items: center;
    padding-left: 17px;
    padding-right: 25px;
}
.lineinput_line_message input{
    width: 650px;
    height: 15px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(28, 30, 32, 1);
    outline: none;
    border: 0px;
}
.but_input_messages{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    cursor: pointer;
}
.but_input_messages svg{
    margin-left: 12px;
}
.but_right_messagese{
    width: 109px;
    height: 14px;
    padding: 20px;
    border-radius: 8px;
    border: 2px solid rgba(245, 197, 56, 1);
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    cursor: pointer;
}
.block_but_message{
    height: 136px;
    max-height: 136px;
}
.messages_line .right_collumn{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}
.messages_line .line_top_message{
    border-bottom: 1px solid rgba(2, 2, 3, 0.4);
    padding-bottom: 20px;
}
.campaing_name{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    text-transform: uppercase;
}

.mesage_val{
    width: 1016px;

    background: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    
}
.text_message{
    min-height: 300px;
    max-height: 300px; 
    overflow-y: scroll;
}
.text_message::-webkit-scrollbar{
    height: 10px;
    width: 6px;
    position: relative;
    right: 50px;
  }
  .text_message::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(244, 245, 247, 1);
}
 
.text_message::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(245, 197, 56, 1);
}
.text_message{
    padding-right: 20px;
    margin-top: 25px;
}

.info_message{

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-top: 16px;
    width: 100%;
    padding: 7px 12px;
    color: #020203;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.info_message div{
    display: flex;
    align-items: center;
}
.info_message svg{
    margin-right: 8px;
}
.telo_text{
    width: 100%;
    height: 100%;
    position: relative;
  
}
.telo_text::-webkit-scrollbar{
    height: 5px;
    width: 5px;
}
.telo_text::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #fff;
    margin-top: 10px;
}
 
.telo_text::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}
/**/
.defalt_text_message{
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.3);
    padding-right: 20px;
}
.copy_message{
    position: absolute;
    right: -5px;
    top: -25px;
    cursor: pointer;
}
.lineinput_line_message_all{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.but_input_messages_generate{
    width: 109px;
    height: 16px;
    padding: 12px 20px;
    border-radius: 8px;
    background: rgba(245, 197, 56, 1);
    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.01em;
    color: #020203;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.but_input_messages_generate:hover{
    background: rgba(244, 245, 247, 1);
}
.but_input_messages_generate:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(95.99deg, rgba(245, 197, 56, 0) 2.77%, #F5C538 100%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.filtercampaing__menu{
    margin-top: -1px !important;
}
.filtercampaing__control--menu-is-open{
    border-bottom-left-radius:0px !important ;
    border-bottom-right-radius:0px !important ;
}
.filtercampaing__menu.css-diyaln-menu{
    border-top-left-radius:0px !important ;
    border-top-right-radius:0px !important ;
}
.filtercampaing__single-value{
    color: hsl(0, 0%, 20%) !important;
}
.ghf{
    width: 115px;
}
.line_top_mes{
    padding: 12px 20px 12px 20px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.line_top_mes_top{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mes_body_left{
    min-width: 544px;
    max-width: 544px;
}
.mes_body_right{
    min-width: 352px;
    max-width: 352px;
}
.but_input_messages_generate.grey{
    background: rgba(237, 239, 242, 1);
    color: rgba(2, 2, 3, 0.15);
}
.form_addnewellement{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center
}
.line_addnewmessage{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}
.input_addnewmessage{
    width: 294px;
    height: 38px;
    background: rgba(45, 50, 57, 1);
    color: rgba(245, 197, 56, 1);
    border: 0px;
    font-size: 15px;
}

.line_addnewmessage .filter__value-container{
    font-size: 15px;
    opacity: 0.6;
}
.line_addnewmessage .filter__menu{
    top: 60px !important;
}
.line_addnewmessage .filter__menu .filter__option{
    opacity: 0.6;
}
.minimessage .filter__menu{
    top: 35px !important;
}
.minimessage  .filter__menu-list {
    padding-bottom: 0px !important;
    margin-bottom:0px !important;
}
.popupInfo_addnewmessage{
    max-height: calc(502px - 92px);
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
}
.popupInfo_addnewmessage .selectplace, .filter__single-value{
    color: rgba(2, 2, 3, 0.6) !important;
}
.filter__control--menu-is-open{
    border-bottom-left-radius:0px !important;
    border-bottom-right-radius:0px !important;
}
.filter__menu{
    border-top-left-radius:0px !important;
    border-top-right-radius:0px !important;
    top: 45px !important;
    cursor: pointer;
}
.filter__option{
    cursor: pointer !important;
}
.titlePopupAddNewMessage{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: #fff;
}
.titlePopupAddNewMessage span{


    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(51, 167, 83, 1);
    text-transform: uppercase;

}
.def_scrollUp{

}
.hov_scrollUp{
    display: none;
}
.scrollUp:hover .hov_scrollUp{
    display: block;
}
.scrollUp:hover .def_scrollUp{
    display: none;
}
.scrollUp {
    position: fixed;
    bottom: 20px;
    right: 55px;
    width: 45px;
    height: 45px;
    cursor: pointer;
}
.hover_minimessage{
    display: flex !important;
}
.hover_minimessage div{
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    z-index: 99999;
}   
.changecurs{
    display: flex;
    justify-content: space-between;
}
.strelka{
    position: absolute;
    right: 0px;
    top: 15px;
    cursor: pointer;
}
.active .element_select_right_title, .active .element_select_right_subtitle{
    color: rgba(51, 167, 83, 1);
}

.mikro_select .element_select.active::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    border: 1px solid;
    background:linear-gradient(91.77deg, #33A753 1.26%, rgba(51, 167, 83, 0) 98.76%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.title_mesa{
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 15.75px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: uppercase;
    color: rgba(66, 133, 244, 1);
}
.line_input_compaing_micro{
    position: relative;
}
.tolltipeMicro{
    padding: 12px;
    border-radius: 4px;
    background: rgba(244, 245, 247, 1);
    position: absolute;
    top: 50px;
    opacity: 0;
    display: none;
}
.line_input_compaing_micro:hover .tolltipeMicro{
    opacity: 1;
    display: block;
}
.select_message_email .filter__menu{
    top: 40px !important;
    width: 352px;
}
.smalmess .filter__menu{
    top: 35px !important;
    width: 176px !important;
}
.smalmess2 .filter__menu{
    top: 39px !important;
    width: 176px !important;
}
.select_message_email .filter__menu .filter__option{
    padding-left: 10px !important;
}
.back_message .hov_svg{
    display: none;
}
.back_message:hover .def_svg{
    display: none;
}
.back_message:hover .hov_svg{
    display: block;
}
.copy_message.empty{
    top: 0px !important;
}