.priview{
    width: calc(100% - 24px);
    display: flex;
    padding: 20px 12px;
    flex-wrap: wrap;
    margin-top: 20px;

    background: rgba(234, 236, 240, 1);
    border-radius: 8px;
}
.priview_body{
    width: calc(100%);
    padding-bottom: 20px;
    border-radius: 8px;
}
.priview_tittle{
    margin-bottom: 20px;
    min-width: 100%;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(2, 2, 3, 1);
    text-transform: uppercase;
}
.priview_body_head{
    display: flex;

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;

    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
    align-items: flex-end;
}
.priview_body_head_col_1{
    min-width: 150px;
    margin-right: 20px;
}
.priview_body_head_col_2{
    min-width: 150px;
    margin-right: 20px;
}
.head_line_padding{
    padding-right: 10px;
    min-width: 140px;
    overflow-wrap: anywhere;
}
.priview_body_body_line{
    display: flex;
    margin-bottom: 12px;
}

.priview_body_head_col_1 input, .priview_body_head_col_2 input{
    width: calc(100% - 20px);
    height: 18px;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    background: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    border: 0px;
}


.priview_body_scroll{
    overflow-x: scroll;
}
.priview_body_scroll::-webkit-scrollbar{
    height: 10px;
    width: 12px;
    margin-bottom: 10px;
  }
  .priview_body_scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
}
 
 .priview_body_scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 8px;
    width: 10px;
    background: rgba(245, 197, 56, 1);
}
.count_prew{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.4);
    margin-bottom: 12px;
    margin-top: 12px;
}