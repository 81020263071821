.segment{
    width: 100%;
    min-height: calc(100vh - 42px);
    position: relative;
}
.header-segment{
    width: calc(100% - 131px);
    height: 40px;
    padding-left: 40px;
    padding-right: 30px;
    margin-top: 42px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.header-segment .slick-slide{
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(2, 2, 3, 0.15);
}
.slick-slider{
    width: calc(100% - 120px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.segment .slick-slider{
    width: calc(100% - 120px);
}

.slick-list{
    max-width: 85%;
    border-bottom: 1px solid rgba(245, 197, 56, 1)
}
.slick-track{
    display: flex !important;
    justify-content: space-around;
}
.slick-slide{
    min-width: calc((100% - 303px)  / 6);
    min-height: 38px;
    display: flex !important;
    align-items: center;
    justify-content: center;

}
.slick-slide div div{
    display: flex !important;
    align-items: center;
}
.createbut{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 1);
    margin-left: 11px;
    margin-right: 11px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.createbut.rty{
    text-transform: capitalize;

    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
}
.createbut.rty3{
    text-transform: capitalize;
    color: rgba(2, 2, 3, 0.6);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.createbut.active{
    background: rgba(245, 197, 56, 1);
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 10px;
    display: flex;
    align-items: center;
    position: relative;
}
.createbut.active:hover {
    background: #fff;
}
.createbut.active:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(281.23deg, #F5C538 0.17%, rgba(245, 197, 56, 0) 54.75%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}

.first_line_sliders{
    width: 100%;
    display: flex;
    align-items: center;
}
.border_line{
    width: calc(100% - 40px);
    border-bottom: 1px solid rgba(245, 197, 56, 1);
    margin-left: 8px;
    margin-top: -1px;
    position: relative;
}
.border_line svg{
    position: absolute;
    right: -40px;
    top: -30px;
}
.header-segment .demo_workspace{
    margin-right: 20px;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(2, 2, 3, 1);
    text-transform: uppercase;

}
.slick-slide div div svg{
    margin-right: 15px;
}
.body-segment{
    widows: 100%;
    margin-top: 64px;
    padding-left: 40px;
    padding-right: 40px;
}
.add-segment-but{
    display: flex;
    max-width: 321px;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(2, 2, 3, 0.15);
    text-transform: uppercase;

    cursor: pointer;
}
.slickpr, .slicknx{
    cursor: pointer;
}
.bottom-segment{
    display: flex;
    width: calc(100% - 140px);
    position: absolute;
    bottom: 43px;
    padding-left: 70px;
    padding-right: 70px;
    justify-content: space-between;
}
.sbutton{
    max-width: 350px;
    min-width: 345px;
}
.dbutton{
    max-width: 245px;
    min-width: 245px;
}

.popupInfo.addwork{
    max-width: calc(1110px - 80px);
    min-height: calc(544px - 92px);
}
.adw{
    max-height: min-content !important;
}
.addwork .separator {
    width: 436px;
}
.datasetblock{
    margin-top: 32px;
}
.title_datasetblock{

    font-family: Manrope;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(174, 174, 178, 1);
    margin-left: 20px;
    margin-bottom: 20px;
}
.upload_dataset{

    max-width: 240px;
    color: rgba(2, 2, 3, 0.6);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.upload_dataset_line{
    min-width: 100%;
    margin-top: 20px;
    padding-bottom: 61px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.upload_dataset:hover{
    color: rgba(2, 2, 3, 1);
}
.hover_upload_dataset{
    display: none;
}
.upload_dataset:hover .hover_upload_dataset{
    display: block;
}
.upload_dataset:hover .defalt_upload_dataset{
    display: none;
}
.addwork .form-element, .addwork .datasetblock{
    padding-left: 24px;
}
.adw .buttonspopup{
    min-width: 260px;
}
.addSegment .pop-input{
    width: 294px;
    height: 56px;
    margin-right: 21px;
}
.addSegment .linenamework{
     display: flex;
}
.button_addsegment{
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(255, 255, 255, 0.15);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 63px;
    
}
.button_addsegment svg{
    margin-right: 12px;
}
.addSegment .css-1dimb5e-singleValue{
    /*color: rgba(245, 197, 56, 1) !important;*/
}
.segment_line_top, .segment_line_inputs{
    display: flex;
    align-items: end;
}
.segment_line_top{
    padding-left: 104px;
    margin-bottom: 20px;
}
.segment_line_top .topname{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(244, 245, 247, 1);
    margin-right: 20px;
}
.tp1{
    min-width: 203px;
}
.tp2{
    min-width: 310px;
    padding-left: 62px;
}
.tp3{
    min-width: 274px;
}
.tp4, .tp5{
    max-width: 88px;
}
.tp6{
    min-width: 315px;
    margin-left: -10px;
}
.tp7{
    min-width: 426px;
}
.popupInfo.addSegment{
    max-width: calc(1334px - 80px) !important;
    left: 80px !important;
    max-height: 650px !important;
    overflow-y: scroll;
}

.inputsegment{
    width: 183px;
    height: 18px;
    padding: 10px;
    border: 0px;
    border-radius: 4px;
    background: rgba(45, 50, 57, 1);
    outline: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}
.segment_line_inputs{
   
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 94px;
}
.inputname{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    padding-left: 10px;
}
.mininput{
    max-width: 68px;
}
.inputname.block2{
    min-width: 333px;
    padding-left: 0px;
}
.inputname.block3{
    min-width: 312px;
    padding-left: 0px;
}
.inputname.block4{
    min-width: 88px;
    padding-left: 0px;
}
.inputname.block5{
    min-width: 315px;
    padding-left: 0px;
}
.inputname.block6{
    min-width: 426px;
    padding-left: 0px;
}
.inputname span{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
}
.segment_lines{
    margin-top: 60px;

}
.noselected{
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
}
.noselected_title{

    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: uppercase;
    color: rgba(66, 133, 244, 1);
    min-width: 100%;
}
.noselected_title span{

    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    text-transform: lowercase;
    color: rgba(244, 245, 247, 1);
    padding-left: 20px;
}
.noselected p{
    min-width: 100%;
    display: block;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 12px;
}  
.noselected_bootom{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(66, 133, 244, 1);
    margin-top: 12px;
} 
.noselected_bootom input{
    margin-left: 20px;
}
.graph{
    min-height: 400px;
    margin-top: 60px;
    display: flex;
}
.css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tickLabel{
    fill: rgba(244, 245, 247, 1) !important;
}
.MuiChartsAxis-directionY .MuiChartsAxis-line{
    stroke: #1C1E20 !important
}
.MuiChartsAxis-directionX .MuiChartsAxis-line, .css-195sd4n-MuiChartsGrid-line{
    stroke: rgba(244, 245, 247, 0.1) !important
}
.css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tick{
    stroke: #1C1E20 !important;
    opacity: 0;
}
.block5 .inputsegment{
    min-width: 295px;
}
.block6 .inputsegment{
    min-width: 396px;
}
.segment_line_inputs{
    position: relative;
}
.segment_line_inputs:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.selectproduct, .inputsegment{
    position: relative;
    z-index: 999;
}
.selectproduct{
    z-index: 9999;
}
.linenamework{
    position: relative;
    z-index: 99999;
}
.title_segments_index{
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 20px;
    padding-left: 93px;
}
.ts1{
    min-width: 315px;
    margin-right: 20px;
}
.ts2{
    min-width: 203px;
    margin-right: 20px;
}
.ts02{
    max-width: 183px;
    min-width: 183px !important;
}
.line_segment_actual{
    height: 38px;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    position: relative;
    align-items: center;
    margin-bottom: 12px;
}
.line_segment_actual div{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 999;
    cursor: pointer;
}
.icon_check{
    margin-right: 20px;
    opacity: 0;
}
.icon_check:first-child{
    margin-right: 4px;
}
.line_segment_actual:hover .icon_check{
    opacity: 1;
}
.line_segment_actual.active .icon_check{
    opacity: 1;
}
.line_segment_actual input{
    width: 295px;
    min-width: 295px;
    height: 18px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(244, 245, 247, 1);
    margin-right: 20px;
    background: #fff;
    position: relative;
    z-index: 999;
}
.actual_segments{
    margin-bottom: 32px;
}   
.hold .actual_segments {
    padding-left: 64px;
}
.line_segment_actual:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}

.line_segment_actual.active::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(90deg, #33A753 9.38%, rgba(51, 167, 83, 0) 68.41%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.selectplace.active{
    color: rgba(255, 255, 255, 0.4) !important;
}
.css-1hb7zxy-IndicatorsContainer span{
    display: none;
}
.filter__indicator-separator{
    display: none;
}
.block_dataset_lines{
    max-height: 460px;
    overflow-y: scroll;

}

.block_dataset_lines::-webkit-scrollbar{
    height: 5px;
    width: 5px;

  }
.block_dataset_lines::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #020203;
    margin-top: 10px;
}
 
.block_dataset_lines::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background:  №020203
    ;
}
.popupInfo.addSegment::-webkit-scrollbar{
    height: 0px;
    width: 0px;

  }
  .popupInfo.addSegment::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #1C1E20;
    margin-top: 10px;
}
 
.popupInfo.addSegment::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #1C1E20;
}


.line_body_lines::-webkit-scrollbar{
    height: 6px;
    width: 6px;

  }
.line_body_lines::-webkit-scrollbar-track {
    border-radius: 5px;
    background:  rgba(242, 242, 247, 1);
    margin-top: 10px;
}
 
.line_body_lines::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}





.workspaceload {
    display: flex;
    height: 100%;
    align-items: center;
}
.activedatasetsegment{
    opacity: 1 !important;
}
.ff0{
    margin-left: 0px !important;
}
.ff0 svg{
    margin-right: 0px !important;
}
.openpopdeploy_tooltipe{
    position: relative;
}
.openpopdeploy_tooltipe:hover .tooltipe{
    opacity: 1;
    z-index: 9999;
    transition: all 0.1s;
}
.tooltipe{
    width: 153px;
    padding: 12px;
    border-radius: 4px;
    background:  rgba(244, 245, 247, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 1);
    position: absolute;
    right: -60px;
    bottom: -80px;
    opacity: 0;
    z-index: -1;
    transition: all 0.1s;
}
.hov_download{
    display: none;
    cursor: pointer;
}
.hover_n:hover .hov_download{
    display: block;
    cursor: pointer;
}
.hover_n:hover .def_download{
    display: none;
    cursor: pointer;
}
.first_s_icon:hover path{
    stroke:rgba(245, 197, 56, 1)
}
.second_s_icon:hover path{
   fill:rgba(245, 197, 56, 1)
}

.first_line_sliders .arowhov:hover path{
    fill: rgba(245, 197, 56, 1)
}
.first_line_sliders:hover .hoverarrowcreatepath{
    fill: rgba(65, 168, 177, 1);
    stroke: rgba(65, 168, 177, 1);
}
.first_line_sliders:hover .rty3{
    color: rgba(2, 2, 3, 1);
}
.first_line_sliders svg{
    margin-right: 10px;
}
.addwork .big_circle{
    border: 2px solid #fff;
}
.addwork .loading_block{
    padding-top: 100px;
}
.addwork .loading_text{
    color: #fff;
}
.MuiChartsAxis-tickLabel{
    fill: rgba(244, 245, 247, 1) !important;
}
.new_form-element{
    margin-top: 27px !important;
}

.new_creat_segment .linenamework{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.new_creat_segment .linenamework span{
    display: block;
    min-width: 100%;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    
}
.pop-input_new_segment{
    width: 400px;
    height: 38px;
    border-radius: 8px ;

    color: rgba(2, 2, 3, 0.4);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    outline: none;
    border: 1px solid rgba(231, 234, 238, 1);
    padding-left: 10px;
    margin-top: 9px;
    background: rgba(255, 255, 255, 1);
}

.addwork .datasetbody.newline{
    margin-top: 0px;
    padding-top: 8px;
    padding-bottom: 8px;
    border: 0px;
    padding-left: 0px;
}
.datasetbody.newline{
    margin-left: 20px;
}
.datasetbody.newline::before{
    display: none;
}
.new_creat_segment .topfield{

    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 1) !important;
}
.new_creat_segment .datasethead{
    padding-left: 20px;
}
.new_creat_segment.addwork .topfield input{
    border: 1px solid rgba(231, 234, 238, 1);
    background: rgba(255, 255, 255, 1);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
}

.new_creat_segment.addwork .field2{
    width: 142px;
}
.new_creat_segment.addwork .tags_dataset{
    border: 1px solid rgba(231, 234, 238, 1);
    background: rgba(255, 255, 255, 1);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    width: 224px;
}
.new_creat_segment.addwork  .field0{
    opacity: 1;
}
.new_creat_segment.addwork .field8{
    width: 224px;
}

.new_creat_segment.addwork .field3, .new_creat_segment.addwork .field4, .new_creat_segment.addwork .field5, .new_creat_segment.addwork .field6{
    width: 142px;
}

.new_creat_segment.addwork .block_dataset_lines{
    overflow: hidden;
}
.line_body_lines{
    overflow: scroll;
    max-height: 440px;
}

.new_creat_segment.addwork .datasetbody:first-child{
    margin-top: 0px;
}
.next_but{
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 73px;
    padding-right: 73px;
    background: rgba(229, 229, 234, 1);
    color: rgba(2, 2, 3, 0.4);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    border-radius: 8px;
}
.next_but.active{
    background: rgba(255, 204, 0, 1);
    color: rgba(2, 2, 3, 1);
    cursor: pointer;
}
.next_but.active:hover{
    background: rgba(65, 168, 177, 1);
    color: rgba(2, 2, 3, 1);
}

.segment .betta_line .betta{
    top: -22px;
    right: -39px;
}
.segment .dataset_head_back:hover .hov_icon{
    top: -24px;
}
.line_new_segment_text{
    font-family: Manrope;
    font-size: 15px;
    font-weight: 600;
    line-height: 15.75px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(174, 174, 178, 1);

}
.list_first_tible_all{
    margin-top: 20px;
    border-radius: 10px;
    display: flex;
}
.list_first_tible{
    border: 1px solid rgba(229, 229, 234, 1);
    width: 300px;
    
    display: flex;
    flex-wrap: wrap;
}
.list_first_tible:last-child{
   
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.list_first_tible:last-child .list_first_tible_title
{
    border-top-right-radius: 10px;
}
.list_first_tible:first-child .list_first_tible_title
{
    border-top-left-radius: 10px;
}
.list_first_tible:first-child{
   
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
   
}
.list_first_tible:first-child .list_first_tible_title svg{
   
    display: none;
   
}
.list_first_tible_title{
    background: rgba(242, 242, 247, 1);
    padding: 10px;
    border-bottom: 1px solid rgba(229, 229, 234, 1);
    font-family: Manrope;
    font-size: 12px;
    font-weight: 700;
    line-height: 12.6px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(28, 30, 32, 0.6);
    min-width: calc(100% - 20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 16px;
}
.list_first_tible_title svg{
    margin-right: 8px;
    cursor: pointer;
}
.list_first_tible_titletext{
    padding: 10px;
    border-bottom: 1px solid rgba(229, 229, 234, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 109px;
    font-family: Manrope;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
}
.list_first_tible_titletext span{
    font-family: Manrope;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none; 
    color: rgba(65, 168, 176, 1);
}
.list_first_tible_list{
    padding: 10px;
    border-bottom: 1px solid rgba(229, 229, 234, 1);
    font-family: Manrope;
    font-size: 12px;
    font-weight: 700;
    line-height: 12.6px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    min-width: calc(100% - 20px);
}
.list_first_tible_list:last-child{
    border-bottom: 0px;
}
.segment .pagination_line{
    padding-left: 0px;
    align-items: center;
}
.segment .pagination{
    display: flex;
    margin-bottom: 34px;
    position: relative;
    left: 45px;
    margin: inherit;
    margin-bottom: 34px;
}
.segment .countpagepagination{
    position: relative;
    top: 0px;
}
.addsegment_new .buttonDataset{
     width: 222px;
}
.addsegment_new .centerflex{
    justify-content: space-between;
}

.new_filtr_datasets .filter__control{
    background: #fff;
    border: 1px solid rgba(229, 229, 234, 1);
}
.new_filtr_datasets .filter__menu{
    border-top: 0px;

}
.new_filtr_datasets .filter__control--is-focused{
    border-color: rgba(229, 229, 234, 1) !important;
}

.new_filtr_datasets .filter__single-value{
    color: rgba(2, 2, 3, 1) !important;
}
.new_filtr_datasets .selectplace.active{
    color: rgba(2, 2, 3, 0.4) !important;
}
.select_line_segments{
    min-width: 100%;
    display: flex;
}
.select_line_segments_f{
    margin-right: 20px;
}
.select_line_segments .select_line_segments span{
    color: rgba(2, 2, 3, 1);
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 8px;
    display: block;
}
.select_line_segments .selectColumn{
    width: 608px;
    padding-top: 10px;

    border: 1px solid rgba(229, 229, 234, 1);
    border-radius: 4px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;

    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(2, 2, 3, 0.4);
    position: relative;
}

.select_line_segments span{
    font-family: Manrope;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
    display: block;
    margin-bottom: 8px;
}
.selectColumn .title_select{
    display: flex;
    flex-wrap: wrap;
}
.filter__indicator-separator{
    display: none !important;
}
.select_line_segments_2{
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
}
.but_apply{
    width: 200px;
    height: 4px;
    padding: 20px;
    border-radius: 8px;
    background: rgba(244, 245, 247, 1);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.4);
    margin-left: 1040px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.but_apply.open{
    background: rgba(245, 197, 56, 1);
    border: 1px solid rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
    cursor: pointer;
}
.selectColumn_block{
    width: 608px;
    padding: 10px;
    border:  1px solid rgba(245, 197, 56, 1);
    border-top: 0px;
    border-bottom-left-radius:8px ;
    border-bottom-right-radius:8px ;
    display: none;
    position: absolute;
    top: 37px;
    left: -1px;
    background: #fff;
    z-index: 999;
}
.selectColumn_block.open{
    display: block;
}
.checks_selectColumn{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
}
.checks_selectColumn svg{
    margin-right: 8px;
    cursor: pointer;
}
.selectColumn.open{
    
    border-bottom-left-radius:0px; 
    border-bottom-right-radius:0px; 
    border:  1px solid rgba(245, 197, 56, 1);
    border-bottom:0px;
}
.line_selectColumn{
    width: 100%;
    height: 1px;
    background: rgba(245, 197, 56, 1);
    position: relative;
    top: -10px;
}
.line_but_aplly_reser{
    display: flex;
    margin-top: 36px;
}
.line_but_aplly_reser .line_reser_but{
    width: 110px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px ;
    border: 1px solid rgba(229, 229, 234, 1);
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 12.6px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
    margin-right: 12px;
    cursor: pointer;
}
.line_but_aplly_reser .line_apply_but{
    width: 110px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px ;
    border: 1px solid rgba(245, 197, 56, 1);
    background: rgba(245, 197, 56, 1);
    font-family: Manrope;
    font-size: 12px;
    font-weight: 600;
    line-height: 12.6px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
    margin-right: 12px;
    cursor: pointer;
}
.minicheck{
    width: 274px;
    height:24px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(244, 245, 247, 1);
    padding-left: 10px;
    margin-right: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 2px;
}
.minicheck svg{
    cursor: pointer;
    
}
.select_line_segments .selectColumn svg{
    min-width: 14px;
    margin-top: 4px;
}
.tlya{
    margin-bottom: 10px;
}
.buttonPopupDelete_select{
    width: 259px;
    height: 58px;
    border-radius: 8px;
    background: rgba(28, 30, 32, 1);
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.buttonPopupDelete_select:hover{
    background: rgba(45, 50, 57, 1);
    color: rgba(245, 197, 56, 1);
}
.buttonPopupDelete_select:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(263.84deg, #F5C538 0%, rgba(28, 30, 32, 0) 97.46%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.buttonPopupDelete_select.active{
    background: rgba(245, 197, 56, 1);
    color: rgba(2, 2, 3, 1);
}
.popupInfo.deleteProduct{
    background: rgba(0, 0, 0, 1);
}
.buttonPopupDelete_select.active:hover{
    background: rgba(65, 168, 177, 1);
}
.buttonPopupDelete_select.active:hover::before{
    display: none;
}
.detail_select{
    width: 1277px;
    height: 156px;
    border-radius: 8px;
    border: 1px solid rgba(229, 229, 234, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(142, 142, 147, 1);
}
.detail_select div{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}
.edit_select_line_segments{
    display: flex;
    flex-wrap: wrap;

}
.edit_select_line_segments .select_line_segments_f{
    min-width: 100%;
    margin-bottom: 20px;
}
.line_edit_column{
    width: 648px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    margin-bottom: 8px;

}
.line_check_column{
    width: 616px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid rgba(229, 229, 234, 1);
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(45, 50, 57, 1);
    display: flex;
    align-items: center;
    padding-left: 10px;
}
.line_edit_column svg{
    cursor: pointer;
}
.multy_check_edit{
    display: flex;
    flex-wrap: wrap;
}
.inputNameColumn{
    width: 639px;
    height:34px;
    border-radius: 4px;
    border: 1px solid rgba(214, 217, 225, 1);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    align-items: center;
    padding-left: 10px;
}   
.ifselect{
    width: 1260px;
    background: rgba(244, 245, 247, 1);
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    margin-top: 13px;
}
.line_ifselect{
    min-width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
}
.if_title{
    width: 74px;
    margin-top: 25px;
}
.if_column{
    width: 310px;
    margin-right: 40px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
}
.if_column span{
    min-width: 100%;
    margin-bottom: 8px;
}
.block_value{
    width: 811px;
    height: 113px;
    padding: 11px;
    border-radius: 6px;
    background: rgba(234, 236, 240, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.block_value .columns_block_value{
    width: 387px;
    display: flex;
    flex-wrap: wrap;
}
.block_value .columns_block_value span{
    display: block;
    min-width: 100%;
    margin-bottom: 8px;
}
.line_add_if_block{
    min-width: 100%;
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 24px;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    
}
.line_add_if_block div{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.line_add_if_block svg{
    margin-right: 8px;
}
.subadd{
    min-width: 100%;
    font-family: Manrope;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(2, 2, 3, 0.6);
    margin-top: 8px;
}
.subadd div{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.subadd svg{
    margin-right: 8px;
}
