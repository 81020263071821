.topfield_block{
    width: calc(100% );
    display: flex;
    margin-top: 32px;

}
.topfield_block .contop_block_right{
    width: 100%;
}
.topfield_block .contop_block_title{
    margin-bottom: 50px;
}
.contop_block_right_body{
    max-width: 773px;
    
}