.addmodel_block
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.models_body{
    width: calc(100% - 40px);
    padding-top: 45px;
    padding-bottom: 60px;
    margin-bottom: 60px;
}

.models_body::-webkit-scrollbar{
    height: 6px;
    width: 10px;
  }
.models_body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(244, 245, 247, 1);
    max-width: 70%;
}
 
 .models_body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(245, 197, 56, 1);
    max-width: 100px;
}


.models_head{
    display: flex;
    margin-bottom: 12px;
    padding-left: 110px;
}
.models_col{
    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin-right: 20px;
}
.text_col{
    min-width: 240px;
}
.num_col{
    min-width: 70px;
}
.models_bottom{

    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;

    margin-top: 40px;
    text-transform: uppercase;
    cursor: pointer;
}
.models_bottom svg{
    margin-right: 12px;
}
.models_line{
    display: flex;
    height: 39px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    margin-left: 56px;
}
.models_line:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1px; 
    background:linear-gradient(270deg, rgba(245, 197, 56, 0.01) 31.11%, #F5C538 82.25%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.models_col_line{
    display: flex;
    margin-right: 20px;
}

.models_col_line input{
    outline: none;
    padding: 10px;
    height: 19px;
    border-radius: 4px;
    border: 1px solid rgba(244, 245, 247, 1);
    color: rgba(0, 0, 0, 0.6);
    background: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;
    position: relative;
    z-index: 999;
}
.text_col input{
    min-width: 203px;
}
.num_col input{
    min-width: 70px;
}
.icon_but{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    opacity: 0;
    min-width: 10px;
    margin-left: 20px;
    margin-right: 20px;
}
.icon_but svg{
    cursor: pointer;
}
.models_line:hover .icon_but{
    opacity: 1;
}

.addmodel_head{
    margin-top: 35px;
    margin-left: 40px;
    margin-right: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(245, 197, 56, 1);
}
.addmodel_head_tabs{
    display: flex;
    align-items: center;
    width: calc(100% - 68px);
    
}
.addmodel_head_tab{
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(2, 2, 3, 0.15);
    margin-right: 20px;
    padding: 10px;
    padding-left: 0px;
    position: relative;
    text-transform: uppercase;
}
.addmodel_head_tab.active{
   
    height: 12px;
    border-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    
    color: rgba(2, 2, 3, 0.6);
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: left;

}

.addmodel_head_tab.active svg{
    margin-left: 18px;
}
.addmodel_head_back{
    cursor: pointer;
    margin-left: 15px;
    position: relative;
    top: 5px;
}
.addmodel_fields{
    background: rgba(244, 245, 247, 1);
    min-width: calc(100% - 120px);
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    border-radius: 12px;
}
.addmodel_body{
    display: flex;
    min-width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
    margin-bottom: 20px;
}
.addmodel_fields_line{
    min-width: 100%;
    display: flex;
    /* margin-bottom: 20px; */
    align-items: center;
    justify-content: space-between;
}
.addmodel_body .selectplace.active{
    color: rgba(2, 2, 3, 0.6) !important;
}
.addmodel_fields_col{
    min-width: 33.3%;
}
.addmodel_fields_col input{
    width: calc(100% - 40px);;
    height: 18px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(244, 245, 247, 1);
    

    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;

    color: rgba(2, 2, 3, 1);
    background: #fff;
    outline: none;
}
/* .addmodel_fields_col input:hover{
    border: 1px solid rgba(245, 197, 56, 1);
} */
.tra3:hover{
    border: 1px solid rgba(245, 197, 56, 1);
}
.tra1{
    color: rgba(2, 2, 3, 0.6) !important;
}

.tra2{
    color: rgba(2, 2, 3, 1) !important;
}
.tra2 .selectplace.active{
    color: rgba(2, 2, 3, 1) !important;
}

.addmodel_fields_col input::placeholder{
    color: rgba(2, 2, 3, 0.6);
}
.addmodel_fields_col input{

}
.addmodel_fields_col label{
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 8px;
    display: block;
    color: rgba(2, 2, 3, 1);
}
.addmodel_button_line{
    display: flex;
    justify-content: center;
    min-width: calc(100% - 20px);
    padding-right: 20px;
}
.addmodel_but{
    width: calc(100%);
    height: 4px;
    padding: 20px;
    border-radius: 8px;
    background: rgba(245, 197, 56, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;
    color: rgba(2, 2, 3, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.addmodel_but:hover{
    background: rgba(65, 168, 177, 1);
}
.segmentation.models.hold{
    transform: translateX(80px);
}
.models.hold .body_segment {
    width: calc(100% - 360px);
}
.models .css-1elkyni-control:hover {
    border-color: rgba(245, 197, 56, 1);
}
.models .css-1excjc6-control:hover
{
    border-color: rgba(245, 197, 56, 1);
}
.models .css-q0h3b9-menu{
    border: 1px solid rgba(245, 197, 56, 1);
    border-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.addmodel_but:hover{
    border: none;
}
/* .addmodel_but:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 2px; 
    background:linear-gradient(95.99deg, rgba(245, 197, 56, 0) 2.77%, #F5C538 100%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
} */
.addmod .def_icon{
    display: block;
    margin-bottom: 5px;
}
.hov_icon{
    display: none;
}
.addmodel_head_back.addmod:hover .def_icon{
    display: none;
}
.addmodel_head_back.addmod:hover .hov_icon{
    display: block;
    margin-bottom: 5px;
}
.addmodel_head_back:hover .def_icon{
    display: none;
}
.addmodel_head_back:hover .hov_icon{
    display: block;
}
.buttons_line{
    width: 1200px;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    margin-bottom: 60px;
}
.buttons_line_v{
    
    display: flex;
    justify-content: space-between;
}
.button_retrain{
    
    height: 0px;
    padding: 20px;
    border-radius: 8px;


    font-size: 18px;
    font-weight: 500;
    line-height: 18.9px;
    letter-spacing: -0.03em;
    text-align: left;


    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(2, 2, 3, 0.6);
    position: relative;
    padding-right: 6px;
}
.button_retrain svg{
    margin-right: 8px;
}

.button_retrain:hover{
    border: 0px;
    background: none;
    color: rgba(2, 2, 3, 1);
}
.button_retrain:hover::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 2px; 
    background:linear-gradient(95.99deg, rgba(245, 197, 56, 0) 2.77%, #F5C538 100%); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
}
.icon_but .ferdta:hover path{
    fill: rgba(245, 197, 56, 1);
}
/* .icon_but .ferdta2:hover path{
    stroke: rgba(245, 197, 56, 1);
} */
.hov_model_but{
    display: none;
}

.models_bottom:hover .def_model_but{
    display: none
}
.models_bottom:hover .hov_model_but{
    display: block;
}
.models_bottom:hover{
    color: rgba(2, 2, 3, 1);
}
.addmodel_block .filter__control--menu-is-open, .addmodel_block .filter__control--menu-is-open{
    border: 1px solid rgba(245, 197, 56, 1);
    border-bottom:0px !important ; 
}
.addmodel_block  .filter__menu{
    border: 1px solid rgba(245, 197, 56, 1);
    border-top:0px !important ;
    border-top-left-radius: 0px !important ;
    border-top-right-radius: 0px !important ; 
}
.addmodel_block .filter__menu-list::-webkit-scrollbar{
    height: 6px;
    width: 6px;
  }
.addmodel_block .filter__menu-list::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(244, 245, 247, 1) !important;
    margin-top: 10px;
}
 
.addmodel_block .filter__menu-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(245, 197, 56, 1);
}
.filter__dropdown-indicator{
    padding-right: 8px !important;
}
.filter__value-container{
    padding-left: 10px !important;
}
.addmodel_block .filter__single-value{
    color: #223!important;
}
.button_retrain.inactive{

    color: rgba(2, 2, 3, 0.15);
}
.button_retrain.inactive:hover{

    color: rgba(2, 2, 3, 0.15);
}
.button_retrain:hover::before{
    content: none;
}
.dow_link{
    cursor: default !important
}
.dow_link_h {
    cursor: pointer !important
}
.dow_link_h path{
    fill: #f5c538;
}
.pagination{
    display: flex;
    min-width: 206px;
    max-width: 206px;
    margin: auto;
    margin-bottom: 61px;
    position: relative;
    left: -45px;
}
.pagenum{
    margin-left: 20px;
    margin-right: 20px;
    color: rgba(2, 2, 3, 0.3);
    font-size: 15px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
}
.pagenum span{
    color: rgba(2, 2, 3, 0.6);
}
.hov_arrow{
    display: none;
}
.arrowP{
    cursor: pointer;
}
.arrowP:hover .hov_arrow{
    display: block;
}
.arrowP:hover .def_arrow{
    display: none;
}
.dubl{
    min-width: 60px;
}
.body-segment.models{
    margin-top: 40px;
}
.controler_top{
    display: flex;
    position: relative;
}
.controler_top .betta{
    left: -55px;
    top: 10px;
}

.addmodel_fields_line_title{
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    color: rgba(66, 133, 244, 1);
    text-transform: uppercase;
}
.detailedeploy{
    min-width: calc(100% - 20px);
    font-size: 16px;
    font-weight: 400;
    line-height: 24.8px;
    letter-spacing: -0.03em;
    text-align: left;
    color:rgba(2, 2, 3, 0.4);
    background: rgba(234, 236, 240, 1);
    padding: 10px;
    border-radius: 8px;
}
.line_detailedeploy{
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
} 
.line_detailedeploy label{
    min-width: 100%;
    margin-bottom: 9px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;

}  
.detailedeploy div{
    margin-bottom: 8px;
}
.blok_metrix{
    display: flex;
    min-width: calc(100% - 120px);
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 40px;
    margin-right: 40px;
    padding: 20px;
    background: rgba(244, 245, 247, 1);
    border-radius: 12px;
    margin-bottom: 32px;
}
.models_block .filter__menu{
    top: 38px !important;
}

.addmodel_block .loading_block{
    margin-top: 70px;
}
.dubl_dow .hov_link{
    display: none;
}
.dubl_dow .def_link{
    display: block;
}
.dubl_dow:hover .hov_link{
    display: block;
}
.dubl_dow:hover .def_link{
    display: none;
}
.icon_disable .def_icon{
    display: block;
}
.icon_disable .hov_icon{
    display: none;
}

.icon_disable{
    cursor: pointer;
    width: 16px;
    height: 12px;
}
.button_retrain .nonactive{
    display: none;
}
.button_retrain.inactive .nonactive{
    display: block;
}
.button_retrain.inactive .def_icon{
    display: none;
}
.button_retrain.inactive .hov_icon{
    display: none;
}


.button_retrain .def_icon{
    display: block;
}
.button_retrain .hov_icon{
    display: none;
}
.button_retrain:hover .def_icon{
    display: none;
}
.button_retrain:hover .hov_icon{
    display: block;
}

.button_retrain.inactive:hover .nonactive{
    display: block;
}
.button_retrain.inactive:hover .def_icon{
    display: none;
}
.button_retrain.inactive:hover .hov_icon{
    display: none;
}
