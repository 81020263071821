.accuracy_block{
    width: calc(100% );
    padding: 20px 0px;
    
    display: flex;
    border-radius: 8px;
    min-width: 100%;
   
}
.accuracy_left{
    min-width: 240px;
    margin-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(234, 236, 240, 1);
    padding-top: 33px;
    padding-bottom: 33px;
    border-radius: 8px;
}
.accuracy_left_title{

    font-size: 16px;
    font-weight: 600;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: center;    
    color: rgba(2, 2, 3, 1);
}
.accuracy_left_value{

    font-size: 56px;
    font-weight: 300;
    line-height: 58.8px;
    letter-spacing: -0.03em;


    text-align: center;
    color: rgba(51, 167, 83, 1);
    margin-top: 10px;
    margin-bottom: 10px;
}
.accuracy_left_subtitle{

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;

    color: rgba(2, 2, 3, 1);
}
.accuracy_left_buttom_text{

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: center;
    color: rgba(51, 167, 83, 1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.accuracy_right{
    display: flex;
    padding: 12px;
    border-radius: 8px;
    flex-wrap: wrap;
    background: rgba(234, 236, 240, 1);
    width: calc(100% - 280px);
    overflow: hidden;
}
.accuracy_right_col{

    min-width: calc(25% - 20px);
    position: relative;

    padding: 10px;
}
.accuracy_right_col0{

    min-width: calc(20% - 20px);
    position: relative;
    max-height: 260px;
    padding: 10px;
}
.accuracy_right_col0.mini{
    min-width: calc(17% - 20px);
}
.accuracy_right_col0.maxi{
    min-width: calc(23% - 20px);
}
.accuracy_right_col label{
    min-width: 100%;

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 1);

    text-align: left;
    max-height: 18px;
    display: block;
    margin-bottom: 12px;
}
.accuracy_right_col input{
    width: calc(100% - 20px);
    height: 18px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid rgba(244, 245, 247, 1);
    background: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    color: rgba(2, 2, 3, 0.6);
    margin-bottom: 12px;
}
.accuracy_right_buttom_text{
    margin-top: 42px;
    color: rgba(2, 2, 3, 0.4);

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;

    position: absolute;
    bottom: 0;
}
.cola2{
    color: rgba(245, 197, 56, 1);
}
.cola3{
    color: rgba(235, 115, 27, 1);
}
.cola4{
    color: rgba(234, 67, 52, 1);
}
.line_1_accur{
    min-width: 100%;
    display: flex;


}
.line_2_accur{
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.line_1_accur::-webkit-scrollbar{
    height: 10px;
    width: 12px;
  }
.line_1_accur::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(244, 245, 247, 1);
}
 
.line_1_accur::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(244, 245, 247, 1);
}
.accuraccy_1_one_blcok{

}
.line_texta_acura{
    min-width: 100%;
    display: flex;
}
.line1_acura{
    margin-bottom: 15px;
    color: rgba(2, 2, 3, 0.4);
}
.accuracy_right_buttom_text svg{
    margin-right: 8px;
}