.topfield_block{
    width: calc(100%);
    display: flex;
    margin-top: 0px;
    margin-bottom: 20px;
}
.topfield_block .contop_block_right{
    width: 100%;
}
.topfield_block .contop_block_title{
    margin-bottom: 20px;
}
.zert{
    position: relative;
    margin-left: 50px;
}
.minusblock{
    
    position: absolute;
    transform: rotate(180deg);
   
}
.tfb2{
   
}
.tfb2 .contop_block_right_body{
    width: calc(100% - 40px);
    height: 800px;
    max-width: calc(100% - 40px);
}

.tfb2 .contop_block_right_body_line div{
    background: rgba(66, 133, 244, 1);
}
.top_rotate_texta{
    max-width: 16px;
    min-width: 16px;
    height: 100%;
}
.top_rotate_texta div{
    transform: rotate(270deg);
    transform-origin: bottom right;
    position: relative;
    left: -20px;

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.04em;
    text-align: center;
    color: rgba(66, 133, 244, 1);

}
.treya{
    min-height: auto;
    border-radius: 0px;
}

.newlineci{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 330px;
}
.yutr{
    display: flex;
    justify-content: center;

    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.04em;
    text-align: center;

    color: rgba(66, 133, 244, 1);
    padding-top: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 50px;
}

.padleft{
    padding-left: 20px;
}
/* .metricright{
    padding-left: 120px;
}
.minmetricright{
    padding-left: 240px;
} */
.topfield_block .contop_block_right_body_line_2{
    /* min-width: calc(100% - 420px); */
}
.placefilter{

    font-size: 16px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.03em;
    text-align: left;

    color: rgba(2, 2, 3, 0.4);
}
.obidspan{


    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
    text-align: left;
    
    color: rgba(2, 2, 3, 1);
}
.filterobid{

    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;

}
.emptymetrix{
    width: calc(100% - 120px);
    min-height: 500px;
    border-radius: 8px;
    background: rgba(244, 245, 247, 1);
    display: flex;
    padding: 30px 60px;
    align-items: center;
}
.centr_metrx{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}
.tfb2 .contop_block_right_body_line{
    margin-bottom: 20px;
}
.linefilter{
    margin-bottom: 27px;
}
.tfb2 .filter__menu{
    top: 45px !important;
}
.tooltipa_head_prew{
    display: none;
    position: absolute;
    z-index: 999;
    background: rgba(244, 245, 247, 1);
    padding: 12px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    border-radius: 8px;
    top: 20px;
}
.head_line_padding{
    position: relative;
}
.open_tooltipa_head_prew:hover .tooltipa_head_prew{
    display: block;
}
.open_tooltipa_head_prew{
position: relative;
cursor: pointer;
}