/* .main-page {
    width: calc(100% - 82px);
    height: 100vh;
    background: url("../../assets/buble.png");
    background-position: right 0px;
    background-size: 860px 860px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 82px;
    background-color: #020203;
    justify-content: center;
} */
 .main-page {
    width: calc(100% - 82px);
    height: 100vh;
    background-position: right 0px;
    background-size: 860px 860px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 82px;
    background-color: #020203;
    justify-content: center;
    position: relative;
    max-height: 100vh;
    overflow: hidden;
}
.central_img{
    background: url("../../assets/fon1scr.png");
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.left_img{
    background: url("../../assets/main1_left.png");
    width: 1200px;
    height: 1200px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(-531px, -139px);
}
.right_img{
    background: url("../../assets/main1_right.png");
    width: 1360px;
    height: 1360px;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translate(570px, 330px);
}
@media (max-width: 900px)
{
    .main-page {
        height: initial;
        min-height: 100vh;
        width: 100%;
    }
}