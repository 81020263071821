.loading_block{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.loading_icon{
    min-width: 100%;
    min-height: 148px;
    display: flex;
    justify-content: center;
}
.loading_text{
    min-width: 100%;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
    letter-spacing: -0.03em;
    text-align: center;
    position: relative;
    z-index: 999;

}
.big_circle{
    width: 106px;
    height: 106px;
    border: 2px solid rgba(2, 2, 3, 1);
    border-radius: 100%;
    position: relative;
}
.small_circle{
    width: 44px;
    height: 44px;
    border-radius: 100%;
    position: absolute;
    background: rgba(66, 133, 244, 1);
    animation: Orbita 15s linear infinite;
    left: 30px;
    top: 30px;
}
@keyframes Orbita {
    from {
      -webkit-transform: rotate(0deg) translateX(100px) rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg) translateX(100px) rotate(-360deg);
    }
}
